<template>
  <div class="info">
    <nav-header></nav-header>
    <!-- banner -->
    <div class="banner"></div>
    <div class="container">
      <!-- 关于我们 -->
      <div class="info-us">
        <!-- 标题 -->
        <div class="info-title">关于我们</div>
        <!-- 内容  -->
        <p>
          盈袋宝(北京)科技有限公司成立于2022年01月19日，注册地位于北京市海淀区杏石口路甲23号3号楼1层1011，经营范围包括技术开发、技术咨询、技术交流、技术转让、技术推广、技术服务；软件服务；软件开发；基础软件服务；应用软件服务；计算机系统服务；软件咨询；产品设计；电脑动画设计；企业策划、设计；设计、制作、代理、发布广告；数据处理（数据处理中的银行卡中心、PUE值在1.4以上的云计算数据中心除外）；销售自行开发的产品。（市场主体依法自主选择经营项目，开展经营活动；依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
        </p>
      </div>

      <!-- 联系我们 -->
      <div class="info-contact" style="display: none">
        <!-- 标题 -->
        <div class="contact-title">联系我们</div>
        <!-- 公司名称 -->
        <div class="contact-item">
          <h2>公司名称:</h2>
          <p>北京商汇天下电子商务有限公司</p>
        </div>
        <!-- 公司电话 -->
        <div class="contact-item">
          <h2>公司电话:</h2>
          <p>15124461060</p>
        </div>
        <div class="contact-item">
          <h2>公司邮箱:</h2>
          <p>15124461060@139.com</p>
        </div>
        <!-- 公司地址 -->
        <div class="contact-item">
          <h2>公司地址:</h2>
          <p>北京市通州区恒业八街6号院26号1层101附10361</p>
        </div>
      </div>
    </div>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
export default {
  mounted() {
    document.title = "盈袋宝";
  },
  components: {
    NavFooter,
    NavHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.info {
  .banner {
    margin-top: 80px;
    width: 100%;
    height: 400px;
    // background-color: pink;
    background: url("/imgs/b1.jpg") no-repeat center;
    background-size: cover;
  }
  .container {
    //   关于我们区块
    .info-us {
      margin: 60px auto;
      // 标题
      .info-title {
        height: 50px;
        font-size: 28px;
        // border-bottom: 3px solid #c60023;
        // width: 80px;
        text-align: center;
        margin: 0 auto;
      }
      p {
        // width: 980px;
        font-size: 21px;
        line-height: 61px;
        padding: 0 30px;
        box-sizing: border-box;
      }
    }

    // 联系我们区块
    .info-contact {
      // 标题
      .contact-title {
        height: 50px;
        font-size: 28px;
        text-align: center;
        margin: 0 auto;
      }

      // 信息列表区块
      .contact-item {
        width: 100%;
        height: 70px;
        // background-color: #c60023;
        margin: 15px auto;
        h2 {
          font-size: 21px;
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
