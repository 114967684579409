<template>
  <div class="ac1">
    <nav-header></nav-header>
    <div class="container">
      <!-- 面包屑导航 -->
      <div class="a1-nav">
        <span @click="goHome()">首页</span>
        <span> > </span>
        <span class="active">行业资讯</span>
      </div>

      <!-- 主容器 -->
      <div class="a1-con">
        <!-- 循环项 -->
        <div
          class="list-item"
          v-for="(item, index) in this.data"
          :key="index"
          @click="gopay(item.id)"
        >
          <!-- 左侧图片 -->
          <div class="item-img">
            <img :src="item.img" alt="" />
          </div>
          <!-- 右侧信息 -->
          <div class="item-info">
            <!-- 标题 -->
            <div class="item-title">{{ item.title }}</div>
            <!-- 发布时间 -->
            <div class="item-time">{{ item.pay }}</div>
            <!-- 简介 -->
            <div class="item-jj">
              {{ item.jj }}
            </div>

            <!-- <div class="item-time">发行时间：{{ item.time }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img style="width: 234px" src="/imgs/pay2.png" />
        <p>扫码付款：2元</p>
      </template>
    </modal>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
export default {
  components: {
    Modal,
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      data: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    document.title = "盈袋宝";
  },
  methods: {
    init() {
      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;
        this.data = data;
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 收费逻辑
    gopay() {
      alert("付款2元后可预览~");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费3元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac1 {
  .container {
    margin-top: 80px;
    box-sizing: border-box;
    padding: 0 100px;
    // 面包屑导航
    .a1-nav {
      //   width: 100%;
      height: 60px;
      display: flex;
      align-items: center;

      span {
        font-size: 21px;
        margin-right: 15px;
        cursor: pointer;
        &.active {
          color: #10e0bb;
        }
      }
    }
    // 主容器
    .a1-con {
      width: 100%;
      // 循环项
      .list-item {
        width: 100%;
        height: 300px;
        border-bottom: 1px solid #cfcfcf;
        @include flex();
        &:hover {
          box-shadow: 0px 0px 6px #9e9b9b;
        }
        // 左侧图片
        .item-img {
          width: 35%;
          height: 100%;
          // background-color: #c60023;
          text-align: center;
          img {
            width: 290px;
            height: 190px;
            margin-top: 56px;
          }
        }
        // 右侧信息
        .item-info {
          width: 65%;
          // 标题
          .item-title {
            font-size: 21px;
          }
          // 发布时间
          .item-time {
            font-size: 16px;
            color: #666;
            margin: 15px auto;
          }
          // 简介
          .item-jj {
            font-size: 14px;
            line-height: 35px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
</style>
