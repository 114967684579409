<template>
  <div class="nav-footer">
    <div class="container">
      <!-- logo区块，上下层 -->

      <!-- 关于我们区块 -->
      <div class="f-info" style="display: none">
        <ul>
          <li>关于我们</li>
          <li><div class="blink"></div></li>
          <li>公司简介</li>
          <li>
            <p>
              经营范围包括网上经营、销售日用品、电子产品、文具用品、化妆品、卫生间用具、家用电器、五金交电、家具；货物进出口、代理进出口、技术进出口；软件开发；应用软件服务；计算机系统服务；基础软件服务；技术推广服务等...
            </p>
          </li>
        </ul>
      </div>
      <!-- 联系我们区块 -->
      <div class="f-list">
        <ul>
          <li>联系我们</li>
          <li><div class="blink"></div></li>
          <li>域名：yingdaipay.com</li>

          <li>邮箱：yingdaibao@yingdaipay.com</li>

          <li>公司地址：北京市海淀区杏石口路甲23号3号楼1层1011</li>
          <li>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #fff"
              >网站备案号：京ICP备2022018150号-1</a
            >
          </li>
        </ul>
      </div>
      <!-- 公司名称 -->
      <div class="f-name">
        <h2>盈袋宝（北京）科技有限公司</h2>
      </div>
    </div>
    <!-- icp 层 -->
    <div class="f-icp" style="display: none">
      <a href="https://beian.miit.gov.cn/#/Integrated/index">网站备案号：京ICP备2022018150号-1</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-footer {
  width: 100%;
  // height: 300px;
  background-color: #353535;
  // margin-top: 100px;
  .container {
    height: 250px;
    @include flex();
    align-items: center;

    //   logo
    .f-logo {
      //   background-color: #fff;
      text-align: center;
      h2 {
        color: #10e0bb;
      }
    }
    // 关于我们区块
    .f-info {
      ul {
        margin-top: 43px;
        li {
          color: #fff;
          margin: 10px auto;
          font-size: 14px;
          .blink {
            width: 80px;
            height: 3px;
            background-color: #fff;
          }
          p {
            width: 200px;
            font-size: 12px;
          }
        }
      }
    }
    // 联系我们
    .f-list {
      ul {
        li {
          color: #fff;
          margin: 20px auto;
          font-size: 14px;
          .blink {
            width: 80px;
            height: 3px;
            background-color: #fff;
          }
        }
      }
    }
    .f-name {
      color: #fff;
      font-size: 16px;
    }
  }
  .f-icp {
    width: 100%;
    height: 50px;
    background-color: #212121;
    line-height: 50px;
    text-align: center;
    a {
      color: #fff;
      font-size: 16px;
    }
  }
}
</style>
