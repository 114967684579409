<template>
  <div class="index">
    <nav-header></nav-header>
    <!-- banner -->
    <div class="banner">
      <img src="/imgs/b1.jpg" alt="" />
    </div>
    <div class="container">
      <!-- 标题 -->
      <div class="m-title">
        <div class="title-user">
          <div class="blink"></div>
          <h2>Industry information</h2>
          <div class="blink"></div>
        </div>
        <h4>行业资讯</h4>
      </div>
      <!-- 内容列表 -->
      <div class="m-list">
        <!-- 循环项 -->
        <div
          class="list-item"
          v-for="(item, index) in this.data"
          :key="index"
          @click="gopay(item.id)"
        >
          <!-- 左侧图片 -->
          <div class="item-img">
            <img :src="item.img" alt="" />
          </div>
          <!-- 右侧信息 -->
          <div class="item-info">
            <!-- 标题 -->
            <div class="item-title">{{ item.title }}</div>
            <!-- 发布时间 -->
            <div class="item-time">{{ item.pay }}</div>
            <!-- 简介 -->
            <div class="item-jj">
              {{ item.jj }}
            </div>
            <!-- <div class="item-time">发布时间：{{ item.time }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img style="width:234px;" src="/imgs/pay2.png" />
        <p>扫码付款：2元</p>
      </template>
    </modal>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
export default {
  name: "index",
  components: {
    Modal,
    NavFooter,
    NavHeader,
  },
  data() {
    return {
      data: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    document.title = "盈袋宝";
  },
  methods: {
    init() {
      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;
        this.data = data.splice(0, 5);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 收费逻辑
    gopay() {
      alert("付款2元后可预览~");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费3元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  margin-top: 80px;
  .banner {
    width: 100%;
    height: 500px;
    // background-color: pink;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    // 标题
    .m-title {
      width: 100%;
      text-align: center;
      margin-top: 60px;
      // 上层
      .title-user {
        display: flex;
        align-items: center;
        margin: 20px auto;
        justify-content: center;
        margin-bottom: 5px;
        h2 {
          font-size: 24px;
          margin: 0 15px;
        }
        .blink {
          width: 80px;
          height: 3px;
          background-color: #666;
        }
      }
      h4 {
        font-size: 18px;
      }
    }

    // 内容列表
    .m-list {
      width: 100%;
      padding: 20px 0;
      box-sizing: border-box;
      // 循环项
      .list-item {
        width: 100%;
        height: 300px;
        border-bottom: 1px solid #cfcfcf;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          box-shadow: 0px 0px 6px #9e9b9b;
        }

        // 左侧图片
        .item-img {
          width: 35%;
          height: 100%;
          // background-color: #c60023;
          text-align: center;
          img {
            width: 290px;
            height: 190px;
            margin-top: 56px;
          }
        }
        // 右侧信息
        .item-info {
          width: 65%;
          // 标题
          .item-title {
            font-size: 21px;
          }
          // 发布时间
          .item-time {
            font-size: 16px;
            color: #666;
            margin: 15px auto;
          }
          // 简介
          .item-jj {
            font-size: 14px;
            line-height: 35px;
            overflow: hidden;
            text-overflow: ellipsis;
            // white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
